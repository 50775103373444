import React, { memo } from 'react'

const InfoTableAbm = () => {

  return (
    <>
      <table className='table info-table'>
        <thead>
          <tr>
            <th className='section-table'>Kolom</th>
            <th>Naam</th>
            <th>Uitleg</th>
            <th>Formaat</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A</td>
            <td>Eenheidscode</td>
            <td>Elke eenheid heeft een eenheidscode</td>
            <td>Tekst. Moet uniek zijn.</td>
          </tr>
          <tr>
              <td>B</td>
              <td>Reparatieonderhoud</td>
              <td>Verplicht veld</td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>C</td>
              <td>Mutatieonderhoud</td>
              <td>Verplicht veld</td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>D</td>
              <td>Planmatig onderhoud</td>
              <td>Verplicht veld</td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>E</td>
              <td>Investeringen woningverbetering</td>
              <td>Verplicht veld</td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>F</td>
              <td>Bouwkosten (nieuwbouw)</td>
              <td></td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>G</td>
              <td>Grondkosten (nieuwbouw)</td>
              <td></td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>H</td>
              <td>Overige kosten (nieuwbouw)</td>
              <td></td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default memo(InfoTableAbm)
